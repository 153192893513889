import React from "react";
import styled from "styled-components";

interface NotFoundProps {}

const NotFound = ({}: NotFoundProps) => {
  return <h1>not found</h1>;
};

export default NotFound;
